<template>
  <div class="my-8 mx-4 w-3/4 relative shadow-primary p-5 rounded-lg">
    <div class="flex justify-between items-center text-lg font-bold mb-4">
        <span>
          {{ t('full_profile.user_details_editor') }}
        </span>
    </div>

    <form class=" my-6 text-left">
      <div class="flex flex-row">
        <div class="flex-grow-1 flex-shrink-1 w-8/12">
          <template v-if="customer.isBusiness">
            <label class="text-black text-sm font-bold w-full mt-6">
              {{ t('full_profile.business') }}
            </label>
            <input
              v-model="customer.company"
              class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">

            <label class="text-black text-sm font-bold w-full mt-6">
              {{ t('full_profile.business_representative') }}
            </label>
            <div class="flex flex-row">
              <div class="w-3/12 mr-8 my-2 flex-shrink-1">
                <Dropdown
                  class="w-full"
                  v-model="customer.namePrefix"
                  :options="namePrefixes"
                  optionValue="value"
                  optionLabel="name"/>
              </div>
              <input
                v-model="customer.name"
                class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">
            </div>
          </template>
          <template v-else>
            <label class="text-black text-sm font-bold mt-6">
              {{ t('full_profile.customer_name') }}
            </label>
            <div class="flex flex-row">
              <div class="w-3/12 mr-8 my-2 flex-shrink-1">
                <Dropdown
                  v-model="customer.namePrefix"
                  :options="namePrefixes"
                  optionValue="value"
                  optionLabel="name"/>
              </div>
              <input
                v-model="customer.name"
                class="flex-shrink-1 w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">
            </div>
          </template>
        </div>
        <div class="flex-grow-0 flex-shrink-0 w-4/12 ml-4">
          <input
            id="is-business"
            v-model="customer.isBusiness"
            :checked="parseInt(customer.isBusiness, 10) === 1"
            class="border-gray-200 mr-2" type="checkbox">
          <label
            for="is-business"
            class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.is_business') }}
          </label>
        </div>
      </div>

      <div class="flex flex-row">
        <div class="flex-grow-1 flex-shrink-1 w-6/12 mr-12">
          <label class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.email_address') }}
          </label>
          <input
            v-model="customer.email"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="email">
        </div>

        <div class="flex-grow-1 flex-shrink-1 w-6/12">
          <label class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.phone') }}
          </label>
          <input
            v-model="customer.phone"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">
        </div>
      </div>


      <div class="flex flex-row mt-12 w-full">
        <div class="w-6/12 mr-12 flex-grow-0 flex-shrink-1">
          <div class="text-black text-md font-bold">
            {{ t('full_profile.billing_address') }}
          </div>

          <label class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.address_line_1') }}
          </label>
          <input
            v-model="customer.addressLine1"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">
          <label class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.address_line_2') }}
          </label>
          <input
            v-model="customer.addressLine2"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">

          <label class="text-black text-sm font-bold mt-6">
            {{ t('full_profile.postcode') }}
          </label>
          <input
            v-model="customer.postcode"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">

          <label class="text-black text-sm font-bold">
            {{ t('full_profile.city') }}
          </label>
          <input
            v-model="customer.city"
            class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text">
        </div>


        <div class="w-6/12 mr-6 flex-grow-0 flex-shrink-1">
          <div class="text-black text-md font-bold">
            {{ t('full_profile.other_addresses') }}
          </div>

          <div class="text-black text-sm font-bold">
            {{ t('full_profile.address_1') }}
          </div>
          <Textarea
            class="mt-2 w-full"
            :autoResize="true"
            rows="3"
            v-model="customer.additionalAddresses[0]" />

          <div class="text-black text-sm font-bold">
            {{ t('full_profile.address_2') }}
          </div>
          <Textarea
            class="mt-2 w-full"
            :autoResize="true"
            rows="3"
            v-model="customer.additionalAddresses[1]" />

          <div class="text-black text-sm font-bold">
            {{ t('full_profile.address_3') }}
          </div>
          <Textarea
            class="mt-2 w-full"
            :autoResize="true"
            rows="3"
            v-model="customer.additionalAddresses[2]" />
        </div>
      </div>
    </form>

      <div>
        <button
          @click="saveCustomer()"
          class="bg-primary text-black w-44 rounded-3xl py-3 text-xs flex justify-center
            items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg">
          {{ t('full_profile.save_customer') }}
        </button>
    </div>
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n';

export default ({
  name: 'CustomerEditor',
  props: {
    getCustomer: Function,
    saveCustomer: Function,
  },
  setup(props) {
    const {t} = new useI18n();
    const customer = props.getCustomer();
    const saveCustomer = props.saveCustomer;
    const namePrefixes = [
      { name: 'Frau', value: 'Frau'},
      { name: 'Herr', value: 'Herr'}
    ];

    return {
      t,
      customer,
      saveCustomer,
      namePrefixes,
    };
  },
});

</script>





