<template>
  <div>
    <div
      v-if="!customerEditor"
      class="my-8 mx-4 w-3/4 relative shadow-primary p-5 rounded-lg"
      :class="customerLoaded ? '' : 'opacity-40 pointer-events-none'">
      <div class="flex justify-between items-center text-lg font-bold mb-4">
        <span>
          {{ t('full_profile.user_details') }}
        </span>
      </div>
      <div class="flex justify-between">
        <div class="flex flex-col justify-between items-center mb-4">
          <span class="text-gray-400 text-xs">
            {{ t('full_profile.account_name') }}
          </span>
          <span class="text-sm my-2">
            {{customer.name}}
          </span>
        </div>
        <div class="flex flex-col justify-between items-center mb-4">
          <span class="text-gray-400 text-xs">
            {{ t('full_profile.email_address') }}
          </span>
          <span class="text-sm my-2">
            {{customer.email}}
          </span>
        </div>
        <div class="flex flex-col justify-between items-center mb-4">
          <span class="text-gray-400 text-xs">
            {{ t('full_profile.user_source') }}
          </span>
          <span class="text-sm my-2">
            {{customer.user_source ? customer.user_source : "Website"}}
          </span>
        </div>
        <div class="flex flex-col justify-between items-center mb-4">
          <span class="text-gray-400 text-xs">
            {{ t('full_profile.user_id') }}
          </span>
          <span class="text-sm my-2">
            {{customer.userId}}
          </span>
        </div>
      </div>

      <div>
        <button
          @click="toggleCustomerEditor"
          class="bg-primary text-black w-44 rounded-3xl py-3 text-xs flex justify-center
          items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg">
          {{ t('full_profile.edit_customer') }}
        </button>
      </div>

    </div>

    <template v-if="customerEditor">
      <CustomerEditor
        :saveCustomer="saveCustomer"
        :getCustomer="getCustomer"
      />
    </template>

    <div class="my-8 mx-4"
         :class="ordersLoaded ? '' : 'opacity-40 pointer-events-none'">
      <div class="flex justify-between items-center text-lg font-bold mb-4">
        <span>
          {{ t('full_profile.users_orders') }}
        </span>
      </div>
      <CustomerOrdersTable :orders="getOrders()"/>
    </div>
  </div>
</template>
<script>

import CustomerOrdersTable from './CustomerOrdersTable.vue'
import CustomerEditor from './CustomerEditor.vue'
import {useRoute} from 'vue-router';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import httpClient from '@/services/httpClient';

export default ({
  name: 'FullProfile',
  components: {
    CustomerOrdersTable,
    CustomerEditor,
  },
  props: {
  },
  setup() {
    const {t} = useI18n();
    const {id} = useRoute().params;
    const customer = ref([]);
    const orders = ref([]);
    const customerLoaded = ref();
    const ordersLoaded = ref();
    const customerEditor = ref(false);

    const fetchCustomer = async (page, filters) => {
      customerLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/single_customer',
        {
          page: page || 1,
          query: id,
          ...filters,
        }
      );
      customer.value = result.data;
      customerLoaded.value = true;
    }

    const fetchOrders = async () => {
      ordersLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/order_find_by_user',
        {
          query: id
        }
      );
      orders.value = result.data;
      console.warn(orders.value);
      ordersLoaded.value = true;
    }

    const toggleCustomerEditor = () => {
      customerEditor.value = !customerEditor.value;
    }

    const getCustomer = () => {
      return customer;
    }

    const saveCustomer = async () => {
      customerLoaded.v
      console.warn('save');
      console.warn({...customer.value});
      toggleCustomerEditor();


      const response = await httpClient.post(
        '/api/v1/dgr/user_details_update', {
          id: customer.value.userId,
          displayName: customer.value.name,
          ...customer.value
        });

      // const result = await httpClient.post(
      //   '/api/v1/dgr/update_user_details',
      //   {
      //     query: id
      //   }
      // );
    }

    const getBackendPath = (path) => {
      return httpClient.backend(path);
    }

    const getOrders = () => {
      return orders;
    }

    onMounted(async () => {
      await fetchCustomer();
      await fetchOrders();
    })

    return {
      t,
      customer,
      getCustomer,
      getBackendPath,
      orders,
      getOrders,
      customerLoaded,
      ordersLoaded,
      customerEditor,
      toggleCustomerEditor,
      saveCustomer,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
