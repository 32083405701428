<template>
  <div class="w-1/5 max-w-xs h-screen p-4 shadow-primary">
    <a :href="websiteUrl" target="_blank">
      <img class="w-full mx-auto" alt="DGR logo" src="@/assets/navbar-logo.png">
    </a>
    <div class="flex flex-col justify-start items-start w-full py-10 ml-1 text-lg">
      <router-link to="/orders" class="w-full"
                   active-class="text-left mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none font-bold bg-secondary">
        <button class="text-left pl-4 mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none">
          {{ t('global_text.all_orders') }}
        </button>
      </router-link>

      <router-link
        v-if="isAdmin"
        to="/customers"
        class="w-full"
        active-class="text-left mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none
        font-bold bg-secondary">
        <button class="text-left pl-4 mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none">
          {{ t('global_text.all_customers') }}
        </button>
      </router-link>

      <router-link to="/my-profile" class="w-full"
                   active-class="text-left mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none font-bold bg-secondary">
        <button class="text-left pl-4 mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none">
          {{ t('global_text.my_profile') }}
        </button>
      </router-link>

      <router-link
        v-if="isAdmin"
        to="/services"
        class="w-full"
        active-class="text-left mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none
        font-bold bg-secondary">
        <button class="text-left pl-4 mb-4 w-full h-10 rounded-lg hover:bg-secondary focus:outline-none">
          {{ t('global_text.services') }}
        </button>
      </router-link>
    </div>
    <div class="absolute bottom-14">
      <div v-if="isAdmin" @click="wpRedirect" class="focus:outline-none px-4 mb-5 cursor-pointer">{{ t('global_text.wp_admin') }} </div>
      <a :href="websiteUrl" class="focus:outline-none px-4 mb-4 cursor-pointer" target="_blank">{{ t('global_text.back_to_home') }} </a>
    </div>
    <div
      class="absolute bottom-2 focus:outline-none px-4 mb-4 cursor-pointer"
      @click="logOut">
      {{ t('global_text.log_out') }}
      <span class="text-gray-400 text-xs">
        [ {{userName()}} ]
      </span>
    </div>
  </div>
</template>

<script>
import {useStore} from 'vuex';
import {onMounted, ref, computed} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import httpClient from '@/services/httpClient';
import {useI18n} from 'vue-i18n';


export default ({
  name: 'Sidebar',
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentRoute = ref('');
    const isAdmin = computed(() => store.getters['AuthModule/isAdmin']);
    const isCustomer = computed(() => store.getters['AuthModule/isCustomer']);
    const userDetails = computed(() => store.getters['AuthModule/userDetails']);
    const {t} = useI18n();


    onMounted(() => {
      currentRoute.value = router.currentRoute;
    })

    function getClass(item) {
      return item === currentRoute.value;
    }

    function userName() {
      return userDetails.value.displayName;
    }

    function logOut() {
      store.dispatch('AuthModule/logout')
      console.log("window.location.href",httpClient.website('/wp-login.php?action=logout'))
      window.location.href = httpClient.website('/wp-login.php?action=logout');
    }

    function wpRedirect() {
      window.location.href = httpClient.backend('');
    }
    function backToHomePage() {
      window.location.href = httpClient.website('');
    }

    const websiteUrl = computed(() => {
      return httpClient.website('');
    })

    return {
      logOut,
      wpRedirect,
      currentRoute,
      getClass,
      isCustomer,
      isAdmin,
      userName,
      t,
      websiteUrl,
      backToHomePage
    }
  },
});

</script>

<style lang="scss" scoped>
</style>
