<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customer_orders_table.order_number') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customer_orders_table.date_ordered') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customer_orders_table.payment_status') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customer_orders_table.payment_method') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customer_orders_table.order_status') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <!--<tr :key="current.id" v-for="current in cases" class="h-12 py-2 text-xs hover:bg-gray-100">
                <td class="px-6 py-4 whitespace-nowrap">{{current.service_name}}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{current.date_ordered}}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{current.order_reference_nr}}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{current.payment_type}}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{current.payment_status}}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{current.payment_method}}</td>
                <td class="px-6 py-4 whitespace-nowrap"><span :class="getStatusClass(current.order_status)">&#9679;</span>{{current.order_status}}</td>
            </tr> -->
              <template v-for="order in orders">
                <tr class="h-12 py-2 text-xs hover:bg-gray-100">
                  <td class="px-6 py-4 whitespace-nowrap text-primary text-center">
                    <router-link :to="`/single-order/${order.orderId}`" class="w-full">
                      <button
                        class="h-8 border border-primary rounded-3xl p-3 flex justify-center items-center text-xs text-primary hover:bg-primary hover:text-white mr-2 focus:outline-none">
                        {{ order.orderId }}
                      </button>
                    </router-link>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ order.orderDate }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ t(`payment_status.${order.paymentStatus}`) }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ order.paymentType }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span :class="`text-${order.status} mr-2`">&#9679;</span>
                    {{ statusArray[order.status] }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { useI18n } from 'vue-i18n';

export default ({
  name: 'Table',
  props: {
    orders: Object
  },
  setup(props) {
    const {t} = new useI18n();
    const orders = props.orders;
    const statusArray = {
      'to_do': 'To do', 'in_progress': 'In progress', 'completed': 'Completed'
    }

    function getStatusClass(status) {
      switch (status) {
        case statusArray[0]:
          return 'text-toDo mr-2';
        case statusArray[1]:
          return 'text-inProgress mr-2';
        case statusArray[2]:
          return 'text-completed mr-2';
      }
    }

    return {
      t,
      statusArray,
      orders,
      getStatusClass
    };
  },
});

</script>

<style lang="scss" scoped>
</style>


