<template>
    <div class="flex overflow-hidden">
        <Sidebar/>
        <div class="flex flex-col p-3 w-4/5 h-screen overflow-x-hidden">
          <div class="text-4xl font-bold mt-6 ml-4">
            {{ t('full_profile.customer_profile') }}
          </div>
            <router-link to="/customers">
              <button class="flex justify-start items-enter mb-4 underline m-4 text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
                  {{ t('full_profile.back_to_all_customers') }}
              </button>
            </router-link>
            <FullProfile/>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/common/Sidebar.vue';
import FullProfile from '@/components/FullProfile.vue';
import {useI18n} from 'vue-i18n';


export default {
    components: {
    Sidebar,
    FullProfile
  },
  setup() {
    const {t} = useI18n();

    return {
      t
    }
  }
}
</script>
